import "core-js/modules/es.function.name.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-25ac34c2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main-container"
};
var _hoisted_2 = {
  class: "center-container dmz-border-radius-10"
};
var _hoisted_3 = {
  class: "label-items dmz-border-radius-10"
};
var _hoisted_4 = {
  class: "list-container"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "number"
};
var _hoisted_7 = {
  class: "article-items"
};
var _hoisted_8 = {
  class: "sidebar-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Item01 = _resolveComponent("Item01");

  var _component_Item02 = _resolveComponent("Item02");

  var _component_Skeleton02 = _resolveComponent("Skeleton02");

  var _component_Pager = _resolveComponent("Pager");

  var _component_SideBar01 = _resolveComponent("SideBar01");

  var _component_AdaptiveLayout01 = _resolveComponent("AdaptiveLayout01");

  return _openBlock(), _createBlock(_component_AdaptiveLayout01, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass(["label-item", {
          'label-item-active': '' === $data.knowledgeType
        }]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.getArticleListByType('');
        })
      }, "全部", 2), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.labelList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["label-item", {
            'label-item-active': item.name === $data.knowledgeType
          }]),
          key: index,
          onClick: function onClick($event) {
            return $options.getArticleListByType(item.name);
          }
        }, [_createTextVNode(_toDisplayString(item.nickName), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(item.count), 1)], 10, _hoisted_5);
      }), 128))])], 512), [[_vShow, $data.itemsType === 3]]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.articleList, function (item, index) {
        return _openBlock(), _createBlock(_component_Item01, {
          key: index,
          itemData: item
        }, null, 8, ["itemData"]);
      }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.knowledgeList, function (item, index) {
        return _openBlock(), _createBlock(_component_Item02, {
          key: index,
          itemData: item,
          itemInde: index + 1
        }, null, 8, ["itemData", "itemInde"]);
      }), 128)), _withDirectives(_createVNode(_component_Skeleton02, {
        number: 5
      }, null, 512), [[_vShow, $data.showSkeleton]]), _createVNode(_component_Pager, {
        page: $data.page,
        pages: $data.pages,
        hasPreviousPage: $data.hasPreviousPage,
        hasNextPage: $data.hasNextPage,
        onToPrevPage: $options.toPrevPage,
        onToNextPage: $options.toNextPage
      }, null, 8, ["page", "pages", "hasPreviousPage", "hasNextPage", "onToPrevPage", "onToNextPage"])])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_SideBar01, {
        sideBarType: $data.sideBarType,
        initCalendar: false
      }, null, 8, ["sideBarType"])])])];
    }),
    _: 1
  });
}