import "core-js/modules/es.json.stringify.js";
import CryptoJS from "../tools/CryptoJS";
import DateTool from "../tools/DateTool";
export default {
  props: ['itemData', 'itemInde'],
  data: function data() {
    return {
      author: '小小多',
      avatarUrl: '/FileService/images/a1/avatar-01.jpg',
      createDateIconUrl: require("../assets/side-images/rl.png")
    };
  },
  methods: {
    toArticleDetails: function toArticleDetails(id) {
      this.$router.push({
        name: "Detail",
        query: {
          key: CryptoJS.encryptByDES(JSON.stringify({
            id: id,
            url: '/api/knowledge/findById'
          })),
          _t: DateTool.getNowTime()
        }
      });
    }
  }
};