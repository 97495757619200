import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "dmz-article-item-01"
};
var _hoisted_2 = {
  class: "title title-top small-screen"
};
var _hoisted_3 = {
  class: "flex-graphics"
};
var _hoisted_4 = {
  class: "image-container"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "content"
};
var _hoisted_7 = {
  class: "title big-screen"
};
var _hoisted_8 = {
  class: "create-info create-info-position big-screen"
};
var _hoisted_9 = {
  class: "info-flex"
};
var _hoisted_10 = {
  class: "author"
};
var _hoisted_11 = ["src"];
var _hoisted_12 = {
  class: "date"
};
var _hoisted_13 = ["src"];
var _hoisted_14 = {
  class: "create-info create-info-bottom small-screen"
};
var _hoisted_15 = {
  class: "info-flex"
};
var _hoisted_16 = {
  class: "author"
};
var _hoisted_17 = ["src"];
var _hoisted_18 = {
  class: "date"
};
var _hoisted_19 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.itemData.title), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
    src: $props.itemData.titleImg,
    alt: "img"
  }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($props.itemData.title), 1), _createElementVNode("div", {
    class: "introduction",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.toArticleDetails($props.itemData.id);
    })
  }, _toDisplayString($props.itemData.contentTop), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
    src: $data.avatarUrl,
    alt: "avatar"
  }, null, 8, _hoisted_11), _createElementVNode("span", null, _toDisplayString($data.author), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
    src: $data.createDateIconUrl,
    alt: "avatar"
  }, null, 8, _hoisted_13), _createElementVNode("span", null, _toDisplayString($props.itemData.createDate), 1)])])])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("img", {
    src: $data.avatarUrl,
    alt: "avatar"
  }, null, 8, _hoisted_17), _createElementVNode("span", null, _toDisplayString($data.author), 1)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("img", {
    src: $data.createDateIconUrl,
    alt: "avatar"
  }, null, 8, _hoisted_19), _createElementVNode("span", null, _toDisplayString($props.itemData.createDate), 1)])])])]);
}