import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "dmz-article-item-02"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "content"
};
var _hoisted_4 = {
  class: "create-info create-info-bottom"
};
var _hoisted_5 = {
  class: "info-flex"
};
var _hoisted_6 = {
  class: "author"
};
var _hoisted_7 = ["src"];
var _hoisted_8 = {
  class: "date"
};
var _hoisted_9 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.itemInde) + "." + _toDisplayString($props.itemData.title), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "introduction",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.toArticleDetails($props.itemData.id);
    })
  }, _toDisplayString($props.itemData.contentTop), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
    src: $data.avatarUrl,
    alt: "avatar"
  }, null, 8, _hoisted_7), _createElementVNode("span", null, _toDisplayString($data.author), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
    src: $data.createDateIconUrl,
    alt: "avatar"
  }, null, 8, _hoisted_9), _createElementVNode("span", null, _toDisplayString($props.itemData.createDate), 1)])])])]);
}