import _createForOfIteratorHelper from "E:/DUMENGZHAO/Work/blog-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import AdaptiveLayout01 from '../components/AdaptiveLayout01.vue';
import SideBar01 from "../components/SideBar01.vue";
import DateTool from "../tools/DateTool";
import CryptoJS from "../tools/CryptoJS";
import Skeleton02 from "../components/Skeleton02";
import Item01 from "../components/Item01";
import Item02 from "../components/Item02";
import Pager from "@/components/Pager";
export default {
  data: function data() {
    return {
      sideBarType: 'items',
      showSkeleton: true,
      itemsType: '',
      author: '小小多',
      avatarUrl: '/FileService/images/a1/avatar-01.jpg',
      createDateIconUrl: require("../assets/side-images/rl.png"),
      articleList: [],
      knowledgeList: [],
      page: 1,
      nextPage: 1,
      limit: 6,
      pages: 0,
      hasPreviousPage: false,
      hasNextPage: false,
      labelList: [],
      knowledgeType: ''
    };
  },
  components: {
    AdaptiveLayout01: AdaptiveLayout01,
    SideBar01: SideBar01,
    Skeleton02: Skeleton02,
    Item01: Item01,
    Item02: Item02,
    Pager: Pager
  },
  created: function created() {
    this.parseRoute(this.$route);
    this.getArticleList();
  },
  methods: {
    getArticleListByType: function getArticleListByType(type) {
      this.knowledgeType = type;
      this.nextPage = 0;
      this.getArticleList();
    },
    getArticleList: function getArticleList() {
      var that = this;
      that.articleList = [];
      that.knowledgeList = [];
      that.showSkeleton = true;
      var url = '';
      var params = {
        page: this.nextPage === 0 ? 1 : this.nextPage,
        limit: this.limit
      };

      if (that.itemsType === 2) {
        url = '/api/article/pageList';
        params['type'] = 1;
      } else if (that.itemsType === 3) {
        url = '/api/knowledge/pageList';
        params['type'] = that.knowledgeType;
      }

      this.get(url, params).then(function (res) {
        that.showSkeleton = false;
        var data = res.data;
        var list = data.list;

        var _iterator = _createForOfIteratorHelper(list),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var d = _step.value;
            d.createDate = DateTool.formatTimestamp(d.createDate);

            if (that.itemsType === 2) {
              that.articleList.push(d);
            } else if (that.itemsType === 3) {
              that.knowledgeList.push(d);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        that.nextPage = data.nextPage;
        that.pages = data.pages;
        that.page = data.pageNum;
        that.hasPreviousPage = data.hasPreviousPage;
        that.hasNextPage = data.hasNextPage;
      }).catch(function (error) {
        console.log(error);
      });
    },
    parseRoute: function parseRoute(route) {
      var key = route.query.key;

      try {
        var query = CryptoJS.decryptByDES(key);
        var json = JSON.parse(query);
        this.itemsType = json.id;

        if (this.itemsType === 3) {
          this.countByType();
        }
      } catch (e) {
        console.error(e);
      }
    },
    toPrevPage: function toPrevPage() {
      if (this.hasPreviousPage) {
        this.getArticleList();
      }
    },
    toNextPage: function toNextPage() {
      if (this.hasNextPage) {
        this.getArticleList();
      }
    },
    countByType: function countByType() {
      var that = this;
      this.get('/api/knowledge/countByType', {}).then(function (res) {
        if (res.code === 200) {
          that.labelList = res.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  },
  mounted: function mounted() {},
  watch: {
    $route: function $route(to) {
      //如果是离开当前路由不执行后续方法
      if (to.fullPath.indexOf("/items") < 0) {
        return;
      }

      this.showSkeleton = true;
      this.parseRoute(this.$route);
      this.nextPage = 0;
      this.getArticleList(); //重新加载数据
    }
  }
};