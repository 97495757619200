export default {
  props: ['pages', 'page', 'hasPreviousPage', 'hasNextPage'],
  setup: function setup(props, context) {
    function toPrevPage() {
      context.emit('toPrevPage');
    }

    function toNextPage() {
      context.emit('toNextPage');
    }

    return {
      toPrevPage: toPrevPage,
      toNextPage: toNextPage
    };
  }
};