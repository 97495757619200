export default {
  name: "AdaptiveLayout01",
  data: function data() {
    return {
      screenWidth: window.innerWidth
    };
  },
  methods: {
    adaptiveLayout: function adaptiveLayout() {
      window.screenWidth = window.innerWidth;
      this.screenWidth = window.screenWidth;
    }
  },
  mounted: function mounted() {
    var that = this;
    that.adaptiveLayout();

    window.onresize = function () {
      return function () {
        that.adaptiveLayout();
      }();
    };
  }
};